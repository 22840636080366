import { Preview, QcReportType } from "@/api/types/receiverUser.types";
import { IFileWithPath } from "@/api/types/senderUser.types";
import {
  isAnySameFileNamesInFileList,
  isAnyDuplicateFolders,
  isAnyEmptyFiles,
  isAnyFilesPathsThatAreTooLong,
  currentMaxStorageLimitInBytes,
  FileExtensionSelector,
} from "@/components/senderForm/senderForm.utils";
import { AppDispatch } from "@/store";
import { setError, setSelectedFiles } from "@/store/slices/senderForm.slice";

export const toFixedNoRounding = (n: number): number => {
  return Math.floor(n * 100) / 100;
};

/**
 * Convert bytes in GB like limit 5000000000 -> 5
 * @param n {number}
 * @return {number}
 */
export const toFixedGB = (n: number): number => n / 1_000_000_000;

/**
 * Convert size to string
 * @param size {number}
 * @return string
 */
export const sizeAndUnitCalc = (size: number) => {
  const marker = 1000;
  const kiloBytes = marker;
  const megaBytes = marker * marker;
  const gigaBytes = marker * marker * marker;

  if (size < kiloBytes) return size + " Bytes";
  else if (size < megaBytes) return toFixedNoRounding(size / kiloBytes) + " KB";
  else if (size < gigaBytes) return toFixedNoRounding(size / megaBytes) + " MB";
  else return toFixedNoRounding(size / gigaBytes) + " GB";
};

export const removeLeadingSlash = (path: string | undefined) =>
  path?.replace(/^\//, ""); // The regex matches a leading slash);

const callShowError = {
  isFileSizeExceeds: (dispatch: AppDispatch) =>
    dispatch(
      setError({
        status: "visible",
        message: "message_dialog.error.fileSizeExceeds",
      }),
    ),
  isSomeFilesEmpty: (dispatch: AppDispatch) =>
    dispatch(
      setError({
        status: "visible",
        message: "message_dialog.error.someFilesEmpty",
      }),
    ),
  isSameFolderName: (dispatch: AppDispatch) =>
    dispatch(
      setError({
        status: "visible",
        message: "message_dialog.error.sameFolderName",
      }),
    ),
  isFilePathTooLong: (dispatch: AppDispatch) =>
    dispatch(
      setError({
        status: "visible",
        message: "message_dialog.error.filePathTooLong",
      }),
    ),
  isSameFileName: (dispatch: AppDispatch) =>
    dispatch(
      setError({
        status: "visible",
        message: "message_dialog.error.sameFileName",
      }),
    ),
};

const isHaveHidden = (path: string) =>
  path.startsWith(".") || path.includes("/.");

export const addFiles = (
  dispatch: AppDispatch,
  droppedFiles: IFileWithPath[],
  allFilesPaths: string[],
  existingFilesTotalSize: number,
  isLoggedInUser: boolean,
  maxStorageFeatureLimit: number,
) => {
  const droppedFilesArray = Array.from(droppedFiles || []).map((file) => {
    const fileWithPath: IFileWithPath = new File([file], file.name);
    fileWithPath.path = removeLeadingSlash(file.path);

    // For unit test
    Object.defineProperty(fileWithPath, "size", { value: file.size });
    return fileWithPath;
  });

  const filteredDroppedFiles = droppedFilesArray.filter(
    // file hidden or file inside hidden folder
    (file) => !isHaveHidden(file.path || file.name),
  );

  const droppedFilesTotalSize: number = filteredDroppedFiles.reduce(
    (totalSize, file) => totalSize + file.size,
    0,
  );

  // check size of files and check size for already added + new files
  const currentMaxFilesTransferSize = currentMaxStorageLimitInBytes(
    isLoggedInUser,
    maxStorageFeatureLimit,
  );
  if (
    droppedFilesTotalSize > currentMaxFilesTransferSize ||
    existingFilesTotalSize + droppedFilesTotalSize > currentMaxFilesTransferSize
  ) {
    callShowError.isFileSizeExceeds(dispatch);
    return;
  }

  // files that have file size of 0
  if (isAnyEmptyFiles(filteredDroppedFiles)) {
    callShowError.isSomeFilesEmpty(dispatch);
    return;
  }

  // Need to check folder that are duplicated, but we still need to check individual file names
  if (isAnyDuplicateFolders(filteredDroppedFiles, allFilesPaths)) {
    callShowError.isSameFolderName(dispatch);
    return;
  }

  if (isAnyFilesPathsThatAreTooLong(filteredDroppedFiles)) {
    callShowError.isFilePathTooLong(dispatch);
    return;
  }

  const combinedDirectoryAndFileNames = [
    ...allFilesPaths,
    ...(filteredDroppedFiles.map(
      (item) =>
        item?.path?.toLocaleLowerCase() ?? item?.name?.toLocaleLowerCase(),
    ) || []),
  ];

  if (isAnySameFileNamesInFileList(combinedDirectoryAndFileNames)) {
    callShowError.isSameFileName(dispatch);

    // add only uniq files
    const itemsWIthUniqNamesAndPaths = filteredDroppedFiles.filter(
      (item) =>
        !allFilesPaths.includes(
          item.path?.toLowerCase() ?? item.name.toLocaleLowerCase(),
        ),
    );
    dispatch(setSelectedFiles(itemsWIthUniqNamesAndPaths));
    return;
  }
  dispatch(setSelectedFiles(filteredDroppedFiles));
};

const IMAGE_FILES_EXTENSIONS = ["jpg", "jpeg", "png", "gif"];
const AUDIO_FILES_EXTENSIONS = ["mp3", "wav"];
const VIDEO_FILES_EXTENSIONS = ["mp4"];
const ZIP_FILE_EXTENSTION = "zip";
const FILE_ZIP_IS_HTML5AD = "File_Zip_Is_Html5Ad";

export const FILE_PREVIEW_TYPE = {
  IMAGE: "image",
  AUDIO: "audio",
  VIDEO: "video",
  HTML: "html",
  UNKNOWN: "unknown",
} as const;

export const getFilePreviewType = (
  fileName: string,
  preview: Preview,
  qcReport: QcReportType[],
) => {
  const originalFileExtenstion = FileExtensionSelector(fileName).toLowerCase();
  if (
    !!preview.largeThumbnailUrl &&
    IMAGE_FILES_EXTENSIONS.includes(originalFileExtenstion)
  ) {
    return FILE_PREVIEW_TYPE.IMAGE;
  }

  const isPreviewProxyExist = !!preview.smallProxyUrl;
  if (!isPreviewProxyExist) {
    return FILE_PREVIEW_TYPE.UNKNOWN;
  }

  const fileExtensionOfProxy = FileExtensionSelector(
    preview.smallProxyUrl!,
  ).toLowerCase();

  if (AUDIO_FILES_EXTENSIONS.includes(fileExtensionOfProxy)) {
    return FILE_PREVIEW_TYPE.AUDIO;
  } else if (VIDEO_FILES_EXTENSIONS.includes(fileExtensionOfProxy)) {
    return FILE_PREVIEW_TYPE.VIDEO;
  }

  if (
    originalFileExtenstion === ZIP_FILE_EXTENSTION &&
    qcReport.some(
      (report) => report.name === FILE_ZIP_IS_HTML5AD && report.value === true,
    )
  ) {
    return FILE_PREVIEW_TYPE.HTML;
  }

  return FILE_PREVIEW_TYPE.UNKNOWN;
};
