// "use client";

import styled from "@emotion/styled";
import Image from "next/image";
import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import theme, { withTransientProps } from "@/styles/theme";

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  ${theme.breakpoints.up("xs")} {
    height: 100%;
    gap: ${(p) => p.theme.spacing(2)};
  }
  ${theme.breakpoints.up("md")} {
    height: 60%;
    gap: ${(p) => p.theme.spacing(3)};
  }
  ${theme.breakpoints.only("md")} {
    padding-right: ${(p) => p.theme.spacing(3)};
  }
`;

export const StyledVisualImg = styled(Image)`
  && {
    position: relative;
    width: auto;
    height: 100%;
    bottom: -2%;
    top: auto;
    margin: auto;
    z-index: -1;
    ${theme.breakpoints.down("lg")} {
      display: none;
    }
    @media screen and (max-width: 780px) {
      max-width: 300px;
      bottom: -13%;
    }
    @media screen and (max-width: 680px) {
      bottom: -16%;
      left: 8%;
    }
  }
`;

interface IParagraphProps {
  $removeLeftMargin?: boolean;
}

export const Paragraph = styled(
  Typography,
  withTransientProps,
)<IParagraphProps>`
  margin: ${(p) => p.theme.spacing(0, 16.5, 0, p.$removeLeftMargin ? 0 : 14)};
  ${theme.breakpoints.down("xl")} {
    margin: ${(p) => p.theme.spacing(0, 0)};
  }}
`;

export const ResponsiveContainer = styled(Container)`
  && {
    display: flex;
    ${theme.breakpoints.up("md")} {
      margin-bottom: ${theme.spacing(10)};
    }
    ${theme.breakpoints.only("lg")} {
      padding: ${(p) => p.theme.spacing(0, 9.5, 0, 9.5)};
    }
    ${theme.breakpoints.only("sm")} {
      padding: ${(p) => p.theme.spacing(7.75, 10.5, 0, 10.5)};
    }
    ${theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  }
`;

export const MainWrapper = styled.main`
  && {
    ${theme.breakpoints.up("xs")} {
      height: calc(100% - ${theme.spacing(7)});
    }
    ${theme.breakpoints.up("sm")} {
      height: calc(100% - ${theme.spacing(8)});
    }
    ${theme.breakpoints.up("md")} {
      margin-top: ${theme.spacing(10)};
      height: calc(100dvh - ${theme.spacing(10)});
    }
    display: flex;
    width: 100%;
  }
`;

export const MainPreviewWrapper = styled.main`
  && {
    ${theme.breakpoints.up("xs")} {
      padding: ${(p) => p.theme.spacing(2)};
    }
    ${theme.breakpoints.up("sm")} {
      padding: ${(p) => p.theme.spacing(3)};
    }
    width: 100%;
    height: 100%;
  }
`;

interface SenderFormWrapperProps {
  $isAnyFilesSelected?: boolean;
}

export const SenderFormStyledGrid = styled(
  Grid,
  withTransientProps,
)<SenderFormWrapperProps>`
  display: flex;
  justify-content: center;

  ${theme.breakpoints.up("xs")} {
    padding-top: ${(p) =>
      p.$isAnyFilesSelected ? p.theme.spacing(2) : p.theme.spacing(10)};
    padding-bottom: ${(p) =>
      p.$isAnyFilesSelected ? p.theme.spacing(0) : p.theme.spacing(28)};
    align-items: ${(p) => (p.$isAnyFilesSelected ? "start" : "center")};
  }
  ${theme.breakpoints.up("sm")} {
    padding-top: ${(p) => p.theme.spacing(10)};
    padding-bottom: ${(p) => p.theme.spacing(28)};
    align-items: center;
  }
  ${theme.breakpoints.up("md")} {
    padding-top: ${(p) => p.theme.spacing(0)};
    padding-bottom: ${(p) => p.theme.spacing(0)};
  }
`;
